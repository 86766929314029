<template>
  <a-spin :spinning="spinning">
    <div class="templete-manage" ref="tm" id="temp-m">
      <!-- :style="{minWidth: editor ? '350px' : '0'}" -->
      <div class="tm-l" id="tml">
        <div class="t-w" v-show="editor">
          <step :s="1" />
        </div>
        <div class="content-w" v-show="editor">
          <div id="myEdit"></div>
          <div class="str-l">
            <!-- <span>当前已输入2568个字符, 您还可以输入77432个字符</span> -->
          </div>
        </div>
        <div class="b-w">
          <a-button
            size="default"
            type="primary"
            style="margin-left: 10px"
            @click="getHtmlContent"
          >
            {{ l("下一步") }}
          </a-button>
          <a-button size="default" @click="preViewPdf">{{
            l("预览")
          }}</a-button>
          <a-button size="default" @click="goBack">{{ l("取消") }}</a-button>
        </div>
      </div>

      <div class="tm-r" id="tmr">
        <div class="item-w">
          <div class="iw-l">模版名称:</div>
          <div class="iw-r">
            <a-input
              v-model="submitParams.name"
              size="small"
              allowClear
              :placeholder="l('请输入')"
            />
          </div>
        </div>

        <div class="item-w">
          <div class="iw-l">协议类型:</div>
          <div class="iw-r">
            <a-select
              size="small"
              style="width: 100%"
              @change="handleStandardChange"
              v-model="submitParams.isStandard"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in standardOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="item-w">
          <div class="iw-l">协议分类:</div>
          <div class="iw-r">
            <a-select
              size="small"
              style="width: 100%"
              @change="handleCategoryChange"
              v-model="submitParams.agreementCategoryId"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in categoryOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="item-w">
          <div class="iw-l">所属分组:</div>
          <div class="iw-r">
            <a-select
              size="small"
              style="width: 100%"
              @change="handleCategoryGroupChange"
              v-model="submitParams.agreementGroupType"
              placeholder="请选择"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in categoryGroupOption"
                :key="i"
              >
                {{ it.label }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div class="desc-w">
          <div class="title">设置模版变量</div>
          <div class="tips">
            快速添加员工姓名、部门、岗位等信息，内容中会自动替换成真实信息
          </div>
        </div>

        <div
          class="fields-w"
          v-for="(item, index) in generalFieldsList"
          :key="index"
        >
          <div class="title">{{ item.groupName }}</div>
          <div class="">
            <ul>
              <li v-for="(it, i) in item.children" :key="i">
                <a-tooltip placement="topLeft" :title="it.showName">
                  <span @click="insertHtml(it)">{{ it.showName }}</span>
                </a-tooltip>
              </li>
            </ul>
          </div>
        </div>

        <div class="item-w">
          <div class="iw-l">签署有效期:</div>
          <div class="iw-r">
            <a-select
              size="small"
              style="width: 100%"
              @change="handleExpriedChange"
              placeholder="签署有效期"
              :disabled="formDisabled"
              v-model="expriedSelectVal"
              allowClear
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in expriedSelectList"
                :key="i"
              >
                {{ it.desc }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div
          class="day-input"
          v-if="expriedSelectVal === -1"
          style="margin-top: 6px"
        >
          <a-input
            size="small"
            placeholder="输入有效日期"
            suffix="天"
            v-model="submitParams.signEffective"
            @blur="signAtChange"
          />
        </div>

        <div class="item-w">
          <div class="iw-l">使用采集表单:</div>
          <div class="iw-r">
            <a-switch
              @change="isShowFormSwitch"
              v-model="isShowFormFields"
              :loading="formDisabled"
            />
          </div>
        </div>

        <div class="item-w" v-show="isShowFormFields">
          <div class="iw-l">选择表单:</div>
          <div class="iw-r">
            <a-select
              size="small"
              style="width: 100%"
              v-model="formSelectDesc"
              @change="handleFormChange"
              :disabled="formDisabled"
            >
              <a-select-option
                :value="it.value"
                v-for="(it, i) in formSelectData"
                :key="i"
              >
                {{ it.text }}
              </a-select-option>
            </a-select>
          </div>
        </div>

        <div
          class="fields-w"
          v-for="(oItem, oIndex) in formFieldsList"
          :key="oIndex"
          v-show="isShowFormFields"
        >
          <div class="title">{{ oItem.groupName }}</div>
          <div class="">
            <ul>
              <li v-for="(_it, _i) in oItem.children" :key="_i">
                <a-tooltip placement="topLeft" :title="_it.showName">
                  <span @click="insertHtml(_it)">{{ _it.showName }}</span>
                </a-tooltip>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>


<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { CommonServiceProxy } from "@/shared/common-service";
import moment from "moment";
// import ReactTable from "@/components/react-table";
// import ReactModify from "@/components/react-modify";
import Step from "./step";
import E from "editor4kg";
import { AppConsts } from "@/abpPro/AppConsts";

export default {
  mixins: [AppComponentBase],
  components: {
    Step,
  },
  data() {
    return {
      spinning: false,
      commonService: null,
      editor: null,
      formDisabled: false,
      submitParams: {
        agreementCategoryId: null, // 协议分类ID
        agreementGroupType: null, // 协议分组
        collectionFormId: null, // 采集表单ID
        agreementType: 1, // 协议类型
        name: "",
        describe: "",
        sort: "",
        signEffective: "",
        content: "", // 模板内容
        isStandard: "", // 是否标准模板
      },
      standardOption: [
        { label: "标准合同", value: "1" },
        { label: "非标准合同", value: "0" },
      ],
      expriedSelectList: [
        { desc: "长期有效", value: 0 },
        { desc: "1天", value: 1 },
        { desc: "2天", value: 2 },
        { desc: "3天", value: 3 },
        { desc: "4天", value: 4 },
        { desc: "5天", value: 5 },
        { desc: "6天", value: 6 },
        { desc: "其他", value: -1 },
      ],
      expriedSelectDesc: "长期有效",
      expriedSelectVal: "",
      categoryOption: [],
      categoryGroupOption: [
        { label: "合同协议", value: 2 },
        { label: "证明文件", value: 1 },
      ],
      generalFieldsList: [],
      formSelectDesc: "",
      formSelectData: [],
      formFieldsList: [],
      isShowFormFields: false,
      ids: [],
      generalFieldsAll: [], // 当前全部通用字段
      formFieldsAll: [], // 当前全部表单字段
    };
  },
  created() {
    // document.getElementsByTagName("body")[0].style.overflow = "hidden";
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.$nextTick(() => {
      this.initEditor();
      // setTimeout(() => {
      //   if (this.$route.params.id !== "create") {
      //     this._agreementTemplateGetEditById(this.$route.params.id);
      //   }
      // }, 500);
    });
  },
  mounted() {
    let tm = this.$el.querySelector("#temp-m");
    let tmr = this.$el.querySelector("#tmr");
    // tm.style.height = window.innerHeight - 64 - 45 + 'px'
    // tm.style.height = window.innerHeight + 50 + 'px'
    // tmr.style.height = window.innerHeight + 50 + 'px'
    tm.style.height = window.innerHeight + "px";
    tmr.style.height = window.innerHeight + "px";
  },
  methods: {
    // 根据id获取模版信息
    async _agreementTemplateGetEditById(id) {
      this.spinning = true;
      this.formDisabled = true;
      this.editor.disable();
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementTemplate/GetEditById",
          params: { id: id },
        });
        this.submitParams = {
          ...res,
          name: res.name,
          sort: res.sort.toString(),
          signEffective: res.signEffective.toString(),
          isStandard: res.isStandard ? "1" : "0",
          status: 1, // 默认启用
          agreementCategoryId: res.agreementCategoryId.toUpperCase(),
          agreementGroupType: res.agreementGroupType && typeof res.agreementGroupType === 'string' ? res.agreementGroupType.toUpperCase() : res.agreementGroupType,
          agreementType:
            res.agreementType === "NaturalPerson" || res.agreementType === 0
              ? 0
              : 1,
        };

        // this.categorySelectDesc = this.categorySelectList.filter(
        //   (item) => item.value.toLowerCase() === res.agreementCategoryId
        // )[0].text;
        if (res.signEffective < 7) {
          this.expriedSelectDesc =
            this.expriedSelectList[res.signEffective].desc;
          this.expriedSelectVal =
            this.expriedSelectList[res.signEffective].value;
        } else {
          this.expriedSelectDesc = this.expriedSelectList[7].desc;
          this.expriedSelectVal = -1;
        }

        if (res.collectionFormId !== null) {
          this.isShowFormFields = true;
          this.submitParams.collectionFormId =
            res.collectionFormId.toUpperCase();
        }

        this._getFormSelectData(
          res.agreementType,
          this.submitParams.generalFieldsOfAgreementContentIds
        );

        this._generalFieldsOfAgreementContentGetList(
          this.submitParams.agreementType,
          this.submitParams.generalFieldsOfAgreementContentIds
        );

        this._agreementCategoryGetSelectData();

        this.editor.txt.html(res.content);
        // clear focus
        let selection = window.getSelection
          ? window.getSelection()
          : document.getSelection();
        selection.removeAllRanges();

        // this._generalFieldsOfAgreementContentGetList(1)
        // this._getFormSelectData(1)
        console.log(this.submitParams);
        console.log(this.editor);
        this.editor.enable();
        this.spinning = false;
        this.formDisabled = false;
      } catch (error) {
        console.log(error);
        this.editor.enable();
        this.spinning = false;
        this.formDisabled = false;
      }
    },
    async initEditor() {
      console.log(123123123);
      const editor = new E(`#myEdit`);
      editor.config.height = window.innerHeight - 70 - 24 - 64 - 45;
      editor.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "backColor",
        "link",
        "list",
        "justify",
        "quote",
        "emoticon",
        "image",
        "video",
        "table",
        "code",
        "splitLine",
        "undo",
        "redo",
        "selectValue",
      ];
      // let generalFields = await this._generalFieldsOfAgreementContentGetList()
      let generalFields = await [];
      editor.create(generalFields);
      this.editor = editor;

      if (this.$route.params.id !== "create") {
        this._agreementTemplateGetEditById(this.$route.params.id);
      } else {
        this._generalFieldsOfAgreementContentGetList(1);
        this._getFormSelectData(1);
        this._agreementCategoryGetSelectData();
      }
    },
    // 获取协议分类
    async _agreementCategoryGetSelectData() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/AgreementCategory/GetSelectData",
          params: null,
        });
        this.categoryOption = res.map((item) => {
          return {
            ...item,
            label: item.text,
          };
        });
        // this.$nextTick(() => {
        //   if (this.$route.params.id !== "create")
        //     this._agreementTemplateGetEditById(this.$route.params.id);
        // });
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    /**
     * 根据表单id获取采集表单字段
     * @id 表单id
     * @curGeneralFieldsOfFormId 修改时存入已有的表单字段组
     */
    async _getListByFormId(id, curGeneralFieldsOfFormId) {
      // console.log(id)
      // console.log(curGeneralFieldsOfFormId)

      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/GeneralFieldsOfAgreementContent/GetListByFormId",
          params: { id: id },
        });
        let arr = [];

        res.map((item) => {
          item.children.map((it) => {
            it.showName = `${it.showName}(表单)`;
            it.type = "formFields";
            arr.push(it);
          });
        });
        this.formFieldsList = res;

        if (curGeneralFieldsOfFormId && curGeneralFieldsOfFormId.length) {
          // 获取当前编辑模版已有通用字段后需要生成当前ids
          arr.map((item) => {
            for (let j = 0; j < curGeneralFieldsOfFormId.length; j++) {
              if (item.id === curGeneralFieldsOfFormId[j]) {
                if (!this.ids.filter((val) => val.key === item.key).length) {
                  this.ids.push({ key: item.key, id: item.id });
                  break;
                }
              }
            }
          });
          // console.log('this.ids:', this.ids)
        }
        this.formFieldsAll = arr;
        this.editor.create(this.generalFieldsAll.concat(arr));

        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * 读取表单Select数据
     * @type 表单类型
     * @curGeneralFieldsOfFormId 修改时存入已有的表单字段组
     */
    async _getFormSelectData(type, curGeneralFieldsOfFormId) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/GeneralFieldsOfAgreementContentGroup/GetFormSelectData",
          params: { type: type },
        });
        this.formSelectData = res;
        if (
          this.submitParams.collectionFormId !== null &&
          !this.formSelectDesc
        ) {
          this.formSelectDesc = res.filter(
            (item) =>
              item.formId.toLowerCase() ===
              this.submitParams.collectionFormId.toLowerCase()
          )[0].value;
          this._getListByFormId(
            this.formSelectDesc,
            curGeneralFieldsOfFormId ? curGeneralFieldsOfFormId : []
          );
        }
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    /**
     * 根据协议类型获取通用字段
     * @type 协议类型
     * @curGeneralFieldsOfAgreementContentIds 修改时存入已有的通用字段组
     */
    async _generalFieldsOfAgreementContentGetList(
      type,
      curGeneralFieldsOfAgreementContentIds
    ) {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/GeneralFieldsOfAgreementContent/GetList",
          params: { type: type }, //默认非自然人
        });
        let arr = [];
        res.map((item, index) => {
          item.children.map((it) => {
            it.type = "generalFields";
            it.showName = `${it.showName}${index + 1}`;
            arr.push(it);
          });
        });
        this.generalFieldsList = res;
        // localStorage.generalFieldsAll = JSON.stringify(arr);
        // console.log('gf-arr:', arr)
        // console.log('gf-curGeneralFieldsOfAgreementContentIds:', curGeneralFieldsOfAgreementContentIds)
        if (curGeneralFieldsOfAgreementContentIds) {
          // 获取当前编辑模版已有通用字段后需要生成当前ids
          arr.map((item) => {
            for (
              let j = 0;
              j < curGeneralFieldsOfAgreementContentIds.length;
              j++
            ) {
              if (item.id === curGeneralFieldsOfAgreementContentIds[j]) {
                if (!this.ids.filter((val) => val.key === item.key).length) {
                  this.ids.push({ key: item.key, id: item.id });
                  break;
                }
              }
            }
          });
          // console.log('this.ids:', this.ids)
        }
        this.generalFieldsAll = arr;
        this.editor.create(this.formFieldsAll.concat(arr));
        this.spinning = false;
        return arr;
      } catch (error) {
        this.spinning = false;
      }
    },
    handleExpriedChange(value) {
      // console.log(`handleExpriedChange ${value}`);
      this.expriedSelectVal = value;
      this.expriedSelectDesc = this.expriedSelectList.filter(
        (item) => item.value === value
      )[0].desc;
      if (value !== -1) {
        this.submitParams.signEffective = value;
      } else {
        this.submitParams.signEffective = "";
      }
    },
    async handleFormChange(value) {
      // console.log(`handleFormChange ${value}`);
      this.submitParams.collectionFormId = value;
      this._getListByFormId(value);
      let txt = await this.editor.txt.html();
      this.clearEditorFields(txt, "formFields");
    },
    handleStandardChange(value) {
      // console.log(`handleStandardChange ${value}`);
      this.submitParams.isStandard = value;
    },
    handleCategoryChange(value) {
      // console.log(`handleCategoryChange ${value}`);
      this.submitParams.agreementCategoryId = value;
    },
    handleCategoryGroupChange(value) {
      // console.log(`handleCategoryChange ${value}`);
      this.submitParams.agreementGroupType = value;
    },
    signAtChange(e) {
      if (e.target.value && Number(e.target.value) > 365) {
        this.submitParams.signEffective = 1;
        this.$message.info("最大日期不能超过365天");
      }
    },
    async isShowFormSwitch(v) {
      if (!v) {
        let txt = await this.editor.txt.html();
        this.clearEditorFields(txt, "formFields");
      }
    },
    // 保存模版
    async _agreementTemplateSave() {
      if (this.submitParams.name === null || this.submitParams.name === "") {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请填写模版名称",
        });
        return;
      }
      if (
        this.submitParams.isStandard === null ||
        this.submitParams.isStandard === ""
      ) {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请选择协议类型",
        });
        return;
      }
      if (
        this.submitParams.agreementCategoryId === null ||
        this.submitParams.agreementCategoryId === ""
      ) {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请选择协议分类",
        });
        return;
      }
      if (
        this.submitParams.agreementGroupType === null ||
        this.submitParams.agreementGroupType === ""
      ) {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请选择所属分组",
        });
        return;
      }
      if (
        this.submitParams.signEffective === null ||
        this.submitParams.signEffective === ""
      ) {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请选择或输入签署有效期",
        });
        return;
      }
      if (
        this.submitParams.content === null ||
        this.submitParams.content === ""
      ) {
        document.body.scrollIntoView();
        this.$notification["warning"]({
          message: "提示",
          description: "请输入模版内容",
        });
        return;
      }
      this.saving = true;
      this.spinning = true;

      let isSelectedFormFields = false;
      for (let i = 0; i < this.formFieldsAll.length; i++) {
        if (
          this.ids.filter((v) => v.id == this.formFieldsAll[i].id.toLowerCase())
            .length
        ) {
          isSelectedFormFields = true;
          break;
        }
      }

      let info = {
        ...this.submitParams,
        status: 1,
        sort: Number(this.submitParams.sort),
        signEffective: Number(this.submitParams.signEffective),
        isStandard: Number(this.submitParams.isStandard) ? true : false,
        collectionFormId:
          !this.isShowFormFields || !isSelectedFormFields
            ? null
            : this.submitParams.collectionFormId,
      };

      let generalFieldsOfAgreementContentIds = this.ids.map((item) => {
        return item.id;
      });
      // console.log('generalFieldsOfAgreementContentIds: ', generalFieldsOfAgreementContentIds)
      info.generalFieldsOfAgreementContentIds =
        generalFieldsOfAgreementContentIds;
      if (this.$route.params.id !== "create") {
        info.id = this.$route.params.id;
      }
      // console.log(this.formSelectData);
      //原来的formId因为结构的问题，取值是字段分组ID，需要重新处理一下，赋值为真的FromId
      if (info.collectionFormId != null && this.formSelectData.length) {
        let realFormId = this.formSelectData.filter((value, index) => {
          return (
            value.value.toLowerCase() == info.collectionFormId.toLowerCase() ||
            value.formId.toLowerCase() == info.collectionFormId.toLowerCase()
          );
        })[0].formId;
        info.collectionFormId = realFormId;
      }

      // console.log(
      //   {
      //     info: info,
      //     generalFieldsOfAgreementContentIds: generalFieldsOfAgreementContentIds,
      //   }
      // )
      try {
        let res = await this.commonService.post({
          url: "/api/services/app/AgreementTemplate/Save",
          params: {
            info: info,
            generalFieldsOfAgreementContentIds:
              generalFieldsOfAgreementContentIds,
          },
        });
        document.body.scrollIntoView();
        this.spinning = false;
        this.saving = false;
        this.goSignMethod(
          res,
          Number(this.submitParams.isStandard) ? "1" : "0"
        );
      } catch (error) {
        this.spinning = false;
        this.saving = false;
      }
    },
    async preViewPdf() {
      this.submitParams.content = await this.editor.txt.html();
      let arr = this.ids;
      arr.map((item, i) => {
        if (this.submitParams.content.indexOf(item.key) === -1) {
          this.ids = this.ids.filter((val) => val.key !== item.key);
        }
      });
      let generalFieldsOfAgreementContentIds = this.ids.map((item) => {
        return item.id;
      });
      try {
        let res = await this.commonService.post({
          url: `/api/services/app/AgreementTemplate/PreViewPdf`,
          params: {
            content: this.submitParams.content,
            generalFieldsOfAgreementContentIds:
              generalFieldsOfAgreementContentIds,
          },
        });
        window.open(`${AppConsts.uploadBaseUrl}${res}`);
      } catch (error) {
        console.log(error);
      }
    },
    async getHtmlContent() {
      // console.log(this.editor.txt.html());
      try {
        this.submitParams.content = await this.editor.txt.html();
        let arr = this.ids;
        arr.map((item, i) => {
          if (this.submitParams.content.indexOf(item.key) === -1) {
            this.ids = this.ids.filter((val) => val.key !== item.key);
          }
        });
        this._agreementTemplateSave();
      } catch (error) {}
    },
    insertHtml(item) {
      let code = item.key;
      let str = item.showName;
      let type;
      if (!this.ids.length) {
        this.ids.push({ key: code, id: item.id });
      } else if (!this.ids.filter((val) => val.key === code).length) {
        this.ids.push({ key: code, id: item.id });
      }
      if (item.type === "generalFields") {
        type = "gf-inserted";
      } else if (item.type === "formFields") {
        type = "ff-inserted";
      }
      // console.log(this.ids)
      this.editor.cmd.do(
        "insertHTML",
        `
          <u class=${type}><span class="${code}">#${str}#</span></u><span>&nbsp;</span>
        `
      );
    },
    clearEditorFields(str, type) {
      let reg;
      let _str;
      if (type === "generalFields") {
        reg = new RegExp('\\<u class="gf-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");
        reg = new RegExp('\\<u class="ff-inserted"(.+?)\\</u>', "g");
        _str = _str.replace(reg, "");
        this.ids = [];
      } else if (type === "formFields") {
        reg = new RegExp('\\<u class="ff-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");

        this.formFieldsList.map((item) => {
          this.ids = this.ids.filter((v) => v.key !== item.id);
        });
      } else {
        reg = new RegExp('\\<u class="atwho-inserted"(.+?)\\</u>', "g");
        _str = str.replace(reg, "");
        this.ids = [];
      }
      // let reg = new RegExp('\\<u class="atwho-inserted"(.+?)\\</u>', "g");
      // let _str = str.replace(reg, "");
      this.editor.txt.html(_str);
    },
    goSignMethod(id, standard) {
      this.$router.push({
        path: `/app/fs/unSignMethod/${id}/${standard}`,
      });
    },
    goBack() {
      // this.$router.back()
      this.$router.push({
        path: `/app/unnaturalperson/templeteManage`,
      });
    },
  },
  beforeDestroy() {
    document.getElementsByTagName("body")[0].style.overflow = "";
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style scoped lang="less">
@import "../../../styles/common.less";

.templete-manage {
  // min-height: 600px;
  position: relative;
  #flex;
  width: 100%;
  min-width: 700px;
  .tm-l {
    // position: absolute;
    // left: 0;
    // width: 72%;
    padding: 0 10px 0 20px;
    #flex1;
    min-width: 350px;
    // #flex1;
    .t-w {
      // background-color: #fff;
    }
    .content-w {
      width: 100%;
      // background-color: #fcf;
      // max-height: 400px;
    }
    .b-w {
      #flex;
      #flex-h-r;
      margin-top: 10px;
      margin-bottom: 50px;
    }
  }

  .tm-r {
    padding: 20px 10px 100px;
    // position: absolute;
    // top: 0;
    // right: 0;
    width: 28%;
    // height: 100%;
    // height: 800px;
    overflow-y: scroll;
    // min-width: 250px;
    width: 320px;
    // background-color: @color-white;
    .item-w {
      #flex;
      #flex-vc;
      margin-bottom: 10px;
      .iw-l {
        #tar;
        padding-right: 5px;
        width: 30%;
        font-size: 12px;
      }
      .iw-r {
        width: 70%;
      }
    }
    .day-input {
      // margin-top: 3px;
      margin-bottom: 10px;
    }
    .desc-w {
      .title {
        #fs14;
      }
      .tips {
        margin-bottom: 3px;
        font-size: 12px;
        color: #80848f;
      }
    }
    .fields-w {
      .title {
        color: @color-black;
        padding: 2px 0;
        #fs14;
      }
      ul {
        #flex;
        #flex-hw;
        #flex-vc;
        padding-left: 0;
        margin-bottom: 0;
        li {
          list-style: none;
          width: 31%;
          margin-right: 1.5%;
          margin-bottom: 1%;
          span {
            #tac;
            #dib;
            padding: 2px 5px;
            #w100;
            #h100;
            border: 1px solid @color-blue;
            #border-radius(3px);
            font-size: 12px;
            background-color: @color-white;
            color: @color-blue;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            &:hover {
              background-color: @color-blue;
              color: @color-white;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
div::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: #e5e5e5;
}
div::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 72%);
  // border-radius: 3px;
}
div::-webkit-scrollbar-thumb:hover {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-thumb:active {
  background-color: #a1a1a1;
}
div::-webkit-scrollbar-track {
  box-shadow: 0 0 0 gray inset;
}
</style>